import React from "react";

export default function NotFound() {
  return (
    <div>
      {" "}
      <h2>404 - Page not found</h2>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
}
